import { format } from "date-fns";
import { ProductConfig } from "../types";

// This function formats a date to the ISO 8601 format eg. yyyy-MM-dd
export const iso8601Date = (date: Date | string) => {
  return format(date, "yyyy-MM-dd");
};

export const cx = (...classNames: string[]) => classNames.filter(Boolean).join(" ");

type EnvKey = "HEMI_API_URL" | "SENTRY_DSN" | "SENTRY_ENVIRONMENT" | "GTM_ID";

export const getEnvVar = (key: EnvKey) => {
  // Attempt to get the value from the window object
  if (window._env_ && window._env_[key]) {
    return window._env_[key];
  }

  // Fallback to import.meta.env
  return import.meta.env[key];
};

export const currency = (value: number) => {
  if (isNaN(value)) return "";

  return (
    new Intl.NumberFormat("da-DK", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value) + " kr."
  );
};

export const getProduct = (productConfig: ProductConfig, productKey?: string | null) =>
  productConfig.products.find((p) => p.key === productKey) || null;
