type State = {
  userType: "guest" | "user";
  productType: "single" | "multi";
  step?: GuestSteps | UserSteps;
  stepNumber?: number;
  totalStepNumbers?: number;
};

type Action = { type: "GO_BACK" } | { type: "GO_NEXT" };
type GuestSteps = (typeof steps.guest.single)[number] | (typeof steps.guest.multi)[number];
type UserSteps = (typeof steps.user.single)[number] | (typeof steps.user.multi)[number];

export const steps = {
  guest: {
    single: ["dates", "personal", "phone", "otp", "confirm", "completed"] as const,
    multi: ["products", "dates", "personal", "phone", "otp", "confirm", "completed"] as const,
  },
  user: {
    single: ["dates", "confirm", "completed"] as const,
    multi: ["products", "dates", "confirm", "completed"] as const,
  },
};

export const stepReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "GO_BACK": {
      const index = steps[state.userType][state.productType].indexOf(state.step as any);

      return {
        ...state,
        step: steps[state.userType][state.productType][index - 1],
        stepNumber: index - 1 + 1,
      };
    }
    case "GO_NEXT": {
      const index = steps[state.userType][state.productType].indexOf(state.step as any);

      return {
        ...state,
        step: steps[state.userType][state.productType][index + 1],
        stepNumber: index + 1 + 1,
      };
    }
    default:
      return state;
  }
};
