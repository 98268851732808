import { useFormContext } from "react-hook-form";
import { Button } from "../components/button";
import { ButtonsWrapper } from "../components/buttons-wrapper";
import { InputOTP } from "../components/input-otp";
import { Stepper } from "../components/stepper";
import { Title } from "../components/text";
import { getProduct } from "../lib/utils";
import { FormFields, StepProps } from "../types";
import { Indentation } from "../components/indentation";

export const StepOtp = ({ productConfig, onBackStep, onNextStep }: StepProps) => {
  const { getValues, setValue, trigger } = useFormContext<FormFields>();
  const product = getProduct(productConfig, getValues("productKey"));
  const phone = getValues("phone");

  const handleGoBack = () => {
    setValue("otp", "");
    onBackStep();
  };

  const handleContinue = async () => {
    const valid = await trigger("otp");
    if (!valid) return;

    onNextStep();
  };

  if (!product) return null;

  return (
    <div>
      <Stepper productConfig={productConfig} />

      <Indentation className="mb-8">
        <Title>Indtast SMS kode</Title>

        <p>
          Hvis du ikke har modtaget koden efter 30 sekunder eller hvis du har tastet et forkert
          mobilnummer ({phone.countryCode} {phone.number}), så{" "}
          <button
            className="underline hover:no-underline"
            onClick={handleGoBack}
            type="button"
            tabIndex={-1}
          >
            klik her for at prøve igen
          </button>
          .
        </p>
      </Indentation>

      <Indentation breakpoint="sm">
        <div className="mb-14">
          <InputOTP />
        </div>
      </Indentation>

      <ButtonsWrapper>
        <Button onClick={handleGoBack} tabIndex={-1} intent="secondary" size="large">
          Tilbage
        </Button>
        <Button onClick={handleContinue} intent="primary" size="large">
          Fortsæt
        </Button>
      </ButtonsWrapper>
    </div>
  );
};
