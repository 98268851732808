import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Button } from "../components/button";
import { ButtonsWrapper } from "../components/buttons-wrapper";
import { Input } from "../components/input";
import { Spinner } from "../components/spinner";
import { Stepper } from "../components/stepper";
import { Title } from "../components/text";
import { getOtp } from "../lib/api";
import { FormFields, StepProps } from "../types";
import { Select } from "../components/select";
import { phonePrefixes } from "../lib/phone-prefixes";
import { Indentation } from "../components/indentation";

export const StepPhone = ({ productConfig, onBackStep, onNextStep }: StepProps) => {
  const { getValues, control, setError, clearErrors, trigger } = useFormContext<FormFields>();
  const [isLoading, setIsLoading] = useState(false);

  const handleContinue = async () => {
    const valid = await trigger("phone");
    if (valid) {
      setIsLoading(true);
      clearErrors();

      const phone = getValues("phone");
      try {
        const response = await getOtp(phone);
        if (response.ok) {
          setIsLoading(false);
          onNextStep();
        } else {
          throw new Error("getOtp returned not ok");
        }
      } catch (error) {
        console.error(error);

        setIsLoading(false);
        setError("phone.number", {
          type: "manual",
          message: "Vi kunne ikke sende koden. Bekræft dit mobilnummer og prøv igen.",
        });
      }
    }
  };

  return (
    <div>
      <Stepper productConfig={productConfig} />

      <Indentation className="mb-8">
        <Title>Indtast dit mobilnummer</Title>
        <p>Du modtager en kode på SMS, som du skal bruge til at bekræfte din booking.</p>
      </Indentation>

      <Indentation breakpoint="sm">
        <div className="flex flex-row gap-x-2">
          <div className="w-24">
            <Controller
              name="phone.countryCode"
              control={control}
              render={({ field, fieldState }) => (
                <Select
                  error={fieldState.error?.message}
                  selectProps={{
                    ...field,
                  }}
                >
                  {phonePrefixes.map((phonePrefix) => (
                    <option key={phonePrefix} value={phonePrefix}>
                      {phonePrefix}
                    </option>
                  ))}
                </Select>
              )}
            />
          </div>
          <Controller
            name="phone.number"
            control={control}
            rules={{
              required:
                "Indtast venligst dit mobilnummer. Du modtager en kode på SMS, som du skal bruge til at bekræfte din booking.",
            }}
            render={({ field, fieldState }) => (
              <Input
                error={fieldState.error?.message}
                inputProps={{
                  ...field,
                  autoFocus: true,
                  placeholder: "Mobilnummer",
                  type: "tel",
                }}
              />
            )}
          />
        </div>
      </Indentation>

      <ButtonsWrapper>
        <Button onClick={onBackStep} tabIndex={-1} intent="secondary" size="large">
          Tilbage
        </Button>
        <Button disabled={isLoading} onClick={handleContinue} intent="primary" size="large">
          {isLoading ? <Spinner color="light" size="sm" /> : "Send kode"}
        </Button>
      </ButtonsWrapper>
    </div>
  );
};
