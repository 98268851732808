export const ClockIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 3H15M12 10V14M20 6L18 8M20 14C20 18.4183 16.4183 22 12 22C7.58172 22 4 18.4183 4 14C4 9.58172 7.58172 6 12 6C16.4183 6 20 9.58172 20 14Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const AnimatedCheckIcon = () => (
  <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg" className="size-6 text-primary-600">
    <g
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        style={{
          strokeDasharray: 76,
          strokeDashoffset: 76,
        }}
        className="animate-[draw-stroke_0.3s_ease-in_0.4s_forwards]"
        d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"
      />
      <path
        style={{
          strokeDasharray: 18,
          strokeDashoffset: 18,
        }}
        className="animate-[draw-stroke_0.3s_ease-in_0.8s_forwards]"
        d="M6.5 13.5L10 17 l8.808621-8.308621"
      />
    </g>
  </svg>
);

export const ExternalLinkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    className="w-3.5 h-3.5"
  >
    <path
      fillRule="evenodd"
      d="M4.25 5.5a.75.75 0 0 0-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 0 0 .75-.75v-4a.75.75 0 0 1 1.5 0v4A2.25 2.25 0 0 1 12.75 17h-8.5A2.25 2.25 0 0 1 2 14.75v-8.5A2.25 2.25 0 0 1 4.25 4h5a.75.75 0 0 1 0 1.5h-5Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M6.194 12.753a.75.75 0 0 0 1.06.053L16.5 4.44v2.81a.75.75 0 0 0 1.5 0v-4.5a.75.75 0 0 0-.75-.75h-4.5a.75.75 0 0 0 0 1.5h2.553l-9.056 8.194a.75.75 0 0 0-.053 1.06Z"
      clipRule="evenodd"
    />
  </svg>
);

export const MapIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.3293 9.16464C19.3293 13.9411 12.1646 21.5136 12.1646 21.5136C12.1646 21.5136 5 13.9411 5 9.16464C5 5.2623 8.2623 2 12.1646 2C16.067 2 19.3293 5.2623 19.3293 9.16464Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M12.1646 11.5529C13.4836 11.5529 14.5528 10.4836 14.5528 9.16464C14.5528 7.84566 13.4836 6.77643 12.1646 6.77643C10.8456 6.77643 9.77637 7.84566 9.77637 9.16464C9.77637 10.4836 10.8456 11.5529 12.1646 11.5529Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const InfoIcon = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx={12} cy={12} r={9} stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
    <path d="M12 11.5L12 16" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
    <path d="M12 8L12 8.5" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
  </svg>
);
