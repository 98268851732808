import { format } from "date-fns";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { AnimatedCheckIcon } from "../components/icons";
import { Title } from "../components/text";
import { getEnvVar, getProduct } from "../lib/utils";
import { FormFields, ProductConfig } from "../types";

declare const window: Window & { dataLayer: Record<string, unknown>[] };

type Props = {
  productConfig: ProductConfig;
};

export const StepCompleted = ({ productConfig }: Props) => {
  const { getValues } = useFormContext<FormFields>();
  const product = getProduct(productConfig, getValues("productKey"))!;
  const productVariant = getValues("productVariant")!;
  const booking = getValues("booking");

  useEffect(() => {
    if (getEnvVar("GTM_ID")) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "purchase",
        currency: "DKK",
        value: productVariant.price,
        transaction_id: `T_${booking?.timestamp}`,
        ecommerce: {
          items: [
            {
              item_id: productVariant.id,
              item_name: product.name,
              location: productVariant.location.value,
              membership: productVariant.membership?.includes("yes") ? "yes" : "no",
              price: productVariant.price,
              quantity: 1,
            },
          ],
          customer: {
            firstName: getValues("firstName"),
            lastName: getValues("lastName"),
            email: getValues("email"),
          },
        },
      });
    }
  }, []);

  return (
    <div role="main">
      <div className="bg-gray-25 rounded-3xl px-7 p-6 text-sm">
        <Title>
          <div className="flex gap-x-2 items-center mb-3">
            <span className="whitespace-nowrap">Booking bekræftet</span>
            <AnimatedCheckIcon />
          </div>
        </Title>

        <div className="mb-6">
          <div>
            {booking?.hcpPicture && (
              <img
                src={booking.hcpPicture}
                alt={booking.hcpName ?? ""}
                className="h-16 ml-4 sm:h-20 w-auto rounded-2xl float-end"
              />
            )}

            {booking?.hcpName && (
              <p className="text-base mb-6 sm:mb-2">
                Din tid er nu booket med <br className="hidden min-[350px]:block sm:hidden" />
                {booking.hcpName}.
              </p>
            )}

            <p className="mb-3">
              Du modtager en bekræftelse på din booking på email.{" "}
              {productVariant.location.value === "video" && (
                <>Mailen indeholder også et link til videokonsultationen.</>
              )}
            </p>
            <p>Vi glæder os til at se dig.</p>
          </div>
        </div>

        <Title size="sm">Din booking</Title>

        <p className="mb-0.5">
          <span className="font-semibold">{product.name}</span>
        </p>

        {booking?.startAt && (
          <p className="font-medium mb-0.5">
            {format(booking.startAt, "d MMMM, 'kl.' HH:mm")} - {format(booking.endAt, "HH:mm")}
          </p>
        )}

        <p>{booking?.location}</p>
      </div>
    </div>
  );
};
