import clsx from "clsx";

export const RadioButtonList = ({ children }: { children: React.ReactNode }) => (
  <div className="grid gap-y-2">{children}</div>
);

type RadioButtonProps = {
  groupName: string;
  className?: string;
  selected: boolean;
  error: boolean;
  value: string | number;
  onClick: () => void;
  children: React.ReactNode;
};

export const RadioButton = (props: RadioButtonProps) => (
  <label
    className={clsx(
      "flex items-start gap-x-2 cursor-pointer rounded-3xl p-6 relative transition-all",
      props.selected ? "bg-base-25" : "bg-base-200",
      props.error ? "ring-1 ring-red-500" : "ring-2 ring-transparent",
      props.className
    )}
    htmlFor={`radio-${props.value}`}
  >
    <div className="flex flex-col">{props.children}</div>

    <input
      id={`radio-${props.value}`}
      type="radio"
      onChange={props.onClick}
      name={props.groupName}
      checked={props.selected}
      className="invisible"
    />

    <CheckedIcon selected={props.selected} error={props.error} />
  </label>
);

const CheckedIcon = ({ selected, error }: { selected: boolean; error: boolean }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    className={clsx(
      "size-6 absolute top-5 right-5",
      selected ? "text-base-1000" : "text-base-400",
      error ? "text-red-500" : ""
    )}
  >
    {selected ? (
      <path d="M2 13L8 19L21 6" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
    ) : (
      <circle cx="12" cy="12" r="9" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
    )}
  </svg>
);
