import { differenceInWeeks, format, isSameDay, isSameHour, isSameMinute, parseISO } from "date-fns";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Button } from "../components/button";
import { ButtonsWrapper } from "../components/buttons-wrapper";
import { Spinner } from "../components/spinner";
import { Stepper } from "../components/stepper";
import { Title } from "../components/text";
import { useGetAvailableDates, useGetAvailableTimes } from "../lib/api";
import { getProduct } from "../lib/utils";
import { AvailableDateResult, FormFields, StepProps } from "../types/";
import clsx from "clsx";
import { Indentation } from "../components/indentation";

const DEFAULT_DAYS_LIMIT = 14;
const DAYS_LIMIT_INCREMENT = 7;

export const StepDates = ({ productConfig, onBackStep, onNextStep }: StepProps) => {
  const { setValue, control, watch, trigger } = useFormContext<FormFields>();
  const { dates, isLoading, daysLimit, setDaysLimit, totalDates } = useDates();
  const selectedDate = watch("date")!;
  const productKey = watch("productKey")!;
  const product = getProduct(productConfig, productKey);
  const isMultiVariant = product?.variants.length !== 1;

  const handleGoBack = () => {
    onBackStep();
  };

  const handleContinue = async () => {
    const valid = await trigger(["date", "time"]);
    if (!valid) return;

    onNextStep();
  };

  const handleSelectDate = (date: Date) => {
    setValue("date", date);
    setValue("time", null);
  };

  const handleShowMoreDates = () => {
    setDaysLimit(daysLimit + DAYS_LIMIT_INCREMENT);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center my-20">
        <Spinner size="sm" color="dark" />
      </div>
    );
  }

  if (totalDates === 0) {
    return (
      <div className="text-center">
        <p className="my-5">Der er desværre ingen ledige tider til {product?.name}.</p>
        {isMultiVariant && <Button onClick={handleGoBack}>Gå tilbage</Button>}
      </div>
    );
  }

  return (
    <div role="main">
      <Stepper productConfig={productConfig} />

      <Indentation className="pb-8">
        <Title>Vælg en dato</Title>
        <p>Vælg en dato for at se ledige tider for {product?.name}.</p>
      </Indentation>

      <div className="grid grid-cols-7 gap-x-2 mb-1 sm:mx-6">
        <div className="text-center">Man</div>
        <div className="text-center">Tirs</div>
        <div className="text-center">Ons</div>
        <div className="text-center">Tors</div>
        <div className="text-center">Fre</div>
        <div className="text-gray-500 text-center">Lør</div>
        <div className="text-gray-500 text-center">Søn</div>
      </div>
      <Indentation breakpoint="sm">
        <div
          data-testid="dates-list"
          className="grid grid-cols-7 gap-x-2 gap-y-3 sm:gap-x-3 sm:gap-y-2"
        >
          {dates
            .filter((_, i) => i < daysLimit)
            .map((date, j) => {
              const selected = isSameDay(date.start, selectedDate);

              return (
                <div
                  className={clsx("flex justify-center", {
                    "cursor-not-allowed": !date.available,
                  })}
                  key={j}
                >
                  <Controller
                    name="date"
                    control={control}
                    rules={{ required: true }}
                    render={({ fieldState }) => (
                      <Button
                        disabled={!date.available}
                        intent={selected ? "primary" : "secondary"}
                        size="small"
                        state={fieldState.error ? "error" : undefined}
                        className="leading-none"
                        onClick={() => handleSelectDate(date.start)}
                      >
                        <span>{format(date.start, "d")}</span>
                        <span className="text-xs block opacity-80">
                          {format(date.start, "MMM")}
                        </span>
                      </Button>
                    )}
                  />
                </div>
              );
            })}
        </div>
      </Indentation>

      <div className="flex justify-center mt-6 mb-12">
        {totalDates > daysLimit && (
          <button className="px-5 underline underline-offset-2" onClick={handleShowMoreDates}>
            Vis flere datoer
          </button>
        )}
      </div>

      <StepTimes />

      <ButtonsWrapper>
        {isMultiVariant ? (
          <Button onClick={handleGoBack} intent="secondary" size="large" tabIndex={-1}>
            Tilbage
          </Button>
        ) : (
          <div></div>
        )}
        <Button
          onClick={handleContinue}
          intent="primary"
          size="large"
          className={clsx(!isMultiVariant ? "w-full sm:w-auto" : "")}
        >
          Fortsæt
        </Button>
      </ButtonsWrapper>
    </div>
  );
};

const StepTimes = () => {
  const { watch, setValue, control } = useFormContext<FormFields>();
  const productVariant = watch("productVariant")!;
  const date = watch("date");
  const { times, isLoading } = useGetAvailableTimes(productVariant, date);
  const selectedTime = watch("time");

  if (!date) return null;
  if (isLoading) {
    return (
      <div className="flex justify-center my-20">
        <Spinner size="sm" color="dark" />
      </div>
    );
  }
  if (times.length === 0) return <p>Der er desværre ingen ledige tider {format(date, "dd-MMM")}</p>;

  return (
    <div>
      <Indentation className="pb-8">
        <Title>Vælg et tidspunkt</Title>
        <p>Vælg et tidspunkt for d. {format(date, "d MMMM")}.</p>
      </Indentation>

      <Indentation breakpoint="sm">
        <div data-testid="times-list" className="grid grid-cols-7 gap-2">
          {times.map((time, j) => {
            const selected =
              selectedTime?.start != null &&
              isSameDay(time.start, selectedTime.start) &&
              isSameHour(time.start, selectedTime.start) &&
              isSameMinute(time.start, selectedTime.start);

            return (
              <div className="flex justify-center" key={j}>
                <Controller
                  name="time"
                  control={control}
                  rules={{ required: true }}
                  render={({ fieldState }) => (
                    <Button
                      intent={selected ? "primary" : "secondary"}
                      size="small"
                      state={fieldState.error ? "error" : undefined}
                      onClick={() => setValue("time", time, { shouldValidate: true })}
                    >
                      {format(time.start, "HH:mm")}
                    </Button>
                  )}
                />
              </div>
            );
          })}
        </div>
      </Indentation>
    </div>
  );
};

// Calculate the number of days to show based on the difference in weeks
// - If the first available date is in 2 weeks, show 14 days
// - If the first available date is in 3 weeks, show 21 days
const getDaysLimit = (date: string) => {
  const weeksDiff = differenceInWeeks(date, new Date());
  const newLimit = Math.max(DEFAULT_DAYS_LIMIT, (weeksDiff + 2) * DAYS_LIMIT_INCREMENT);
  return newLimit;
};

const useDates = () => {
  const [daysLimit, setDaysLimit] = useState(DEFAULT_DAYS_LIMIT);
  const { setValue, watch, getValues } = useFormContext<FormFields>();
  const productVariant = watch("productVariant")!;

  const { dates, isLoading } = useGetAvailableDates(productVariant, {
    onSuccess: (result: AvailableDateResult[] | { message: string }) => {
      // If there is an error message, do nothing
      if ("message" in result) return;

      // If the date field is already set, update the days limit.
      // Happens when the user clicks back to the previous step.
      const currentDate = getValues("date");
      if (currentDate) {
        const newDaysLimit = getDaysLimit(currentDate.toString());
        setDaysLimit(newDaysLimit);
      } else {
        // Set the first available date as the default date
        const firstAvailable = result.find((date) => date.available);
        if (firstAvailable) {
          const newDaysLimit = getDaysLimit(firstAvailable.start);
          setDaysLimit(newDaysLimit);
          setValue("date", parseISO(firstAvailable.start));
        }
      }
    },
  });

  return {
    dates,
    isLoading,
    daysLimit,
    setDaysLimit,
    totalDates: Object.keys(dates).length,
  };
};
