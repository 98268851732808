import clsx from "clsx";

export const Title = ({
  size = "lg",
  children,
}: {
  size?: "lg" | "sm";
  children: React.ReactNode;
}) => (
  <h2
    className={clsx(
      "font-serif",
      size === "sm" ? "font-medium text-xl leading-none mb-4" : "",
      size === "lg" ? "font-normal text-3xl leading-tight mb-2" : ""
    )}
  >
    {children}
  </h2>
);
