import * as Sentry from "@sentry/browser";
import { ErrorBoundary } from "@sentry/react";
import { setDefaultOptions } from "date-fns";
import { da } from "date-fns/locale";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import { ErrorBoundaryFallback } from "./components/errors.tsx";
import { getEnvVar } from "./lib/utils.ts";
import "./style.css";

if (getEnvVar("SENTRY_DSN")) {
  Sentry.init({
    dsn: getEnvVar("SENTRY_DSN"),
    environment: getEnvVar("SENTRY_ENVIRONMENT"),
  });
}

setDefaultOptions({ locale: da });

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get("token") || undefined;
const productKey = urlParams.get("key") || undefined;

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ErrorBoundary fallback={ErrorBoundaryFallback}>
      <App token={token} productKey={productKey} />
    </ErrorBoundary>
  </React.StrictMode>
);
