import { add, differenceInDays, formatISO, isSameDay, nextSunday, startOfWeek } from "date-fns";
import useSWR, { SWRConfiguration } from "swr";
import {
  AvailableDate,
  AvailableDateResult,
  AvailableTime,
  AvailableTimeResult,
  ProductConfig,
  ProductVariant,
} from "../types";
import { getEnvVar, iso8601Date } from "./utils";
import ky from "ky";
import { v4 as uuidv4 } from "uuid";

const API_ROOT = getEnvVar("HEMI_API_URL");

const defaultOptions: SWRConfiguration = {
  revalidateOnFocus: false,
};

const getApiHeaders = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token") || undefined;
  const deviceId = urlParams.get("deviceId") || uuidv4();

  // UTM params
  const utmKeys = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];
  const utmParams = utmKeys.reduce((acc, key) => {
    const value = urlParams.get(key);
    if (value) {
      acc[key] = value;
    }
    return acc;
  }, {} as any);

  return {
    Authorization: `Bearer ${token}`,
    "x-device-id": deviceId,
    "x-utm-source": utmParams.utm_source,
    "x-utm-medium": utmParams.utm_medium,
    "x-utm-campaign": utmParams.utm_campaign,
    "x-utm-term": utmParams.utm_term,
    "x-utm-content": utmParams.utm_content,
    "Content-Type": "application/json",
  };
};

const api = ky.create({
  prefixUrl: API_ROOT,
  headers: getApiHeaders(),
});

export const useGetProducts = () => {
  const url = `online-booking/products`;
  const { data, isLoading, error } = useSWR<ProductConfig>(
    url,
    async (...args: Parameters<typeof ky>) => await api(...args).json(),
    defaultOptions
  );

  if (error) {
    throw new Error(error);
  }

  return {
    data,
    isLoading,
  };
};

export const useGetAvailableDates = (
  productVariant: ProductVariant,
  options?: SWRConfiguration
) => {
  const start = startOfWeek(new Date(), {
    weekStartsOn: 1, // Monday
  });
  const end = nextSunday(add(start, { weeks: 5 }));
  const url = `online-booking/available-dates?from=${iso8601Date(start)}&to=${iso8601Date(
    end
  )}&productId=${productVariant.id}`;

  const { data, isLoading, error } = useSWR<AvailableDateResult[]>(
    url,
    async (...args: Parameters<typeof ky>) => await api(...args).json(),
    {
      ...defaultOptions,
      ...options,
    }
  );

  if (error) {
    throw new Error(error);
  }

  if (data && data.length > 0) {
    let dates: AvailableDate[] = [];
    const diffInDays = differenceInDays(end, start) + 1;

    // Using the start date as a reference
    // create a range of booking dates. If
    // a date doesn't exist in the data, add
    // it as unavailable.
    for (let i = 0; i < diffInDays; i++) {
      const day = add(start, { days: i });
      const date = data.find((d) => isSameDay(d.start, day));

      if (date) {
        dates.push({
          start: day,
          available: date.available,
        });
      } else {
        dates.push({
          start: day,
          available: false,
        });
      }
    }

    // Sort by start date
    dates = dates.sort((a, b) => a.start.getTime() - b.start.getTime());

    return {
      dates,
      isLoading,
    };
  }

  return {
    dates: [],
    isLoading,
  };
};

export const useGetAvailableTimes = (productVariant: ProductVariant, date: Date | null) => {
  const isoDate = date ? iso8601Date(date) : "";
  const url = `online-booking/available-times?date=${isoDate}&productId=${productVariant.id}`;
  const { data, isLoading, error } = useSWR<AvailableTimeResult[]>(
    url,
    date ? async (...args: Parameters<typeof ky>) => await api(...args).json() : null,
    defaultOptions
  );

  if (error) {
    throw new Error(error);
  }

  if (data && data.length > 0) {
    const times: AvailableTime[] =
      data.map((d) => ({
        start: new Date(d.start),
        end: new Date(d.end),
        calendarId: d.calendarId,
        employeeId: d.employeeId,
      })) || [];

    return {
      times,
      isLoading,
    };
  }

  return {
    times: [],
    isLoading,
  };
};

export const getOtp = async (phone: { countryCode: string; number: string }) =>
  await api.post(`auth/otp`, {
    json: { phone: `${phone.countryCode}${phone.number}` },
  });

type SignupPayload = {
  cpr: string;
  otp: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: { countryCode: string; number: string };
};

export const signup = async (payload: SignupPayload) =>
  await api.post(`auth/signup-login`, {
    json: {
      ...payload,
      phone: `${payload.phone.countryCode}${payload.phone.number}`,
    },
  });

type BookPayload = {
  productId: number;
  calendarId: number;
  employeeId: number;
  cpr: string;
  start: string;
  end: string;
  acceptNewsletter?: boolean;
};

export const book = async (token: string, payload: BookPayload) =>
  await api.post(`bookings`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    json: {
      ...payload,
      start: formatISO(payload.start),
      end: formatISO(payload.end),
    },
  });
