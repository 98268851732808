import { Controller, useFormContext } from "react-hook-form";
import { Button } from "../components/button";
import { ProductContent } from "../components/product-content";
import { RadioButton, RadioButtonList } from "../components/radio-button";
import { Stepper } from "../components/stepper";
import { Title } from "../components/text";
import { getProduct } from "../lib/utils";
import {
  FormFields,
  Product,
  ProductLocationOption,
  ProductMembershipOption,
  StepProps,
} from "../types/";
import { Indentation } from "../components/indentation";

type Props = Omit<StepProps, "onBackStep">;

export const StepProduct = ({ productConfig, onNextStep }: Props) => {
  const { control, setValue, getValues, trigger, watch } = useFormContext<FormFields>();
  const productVariant = watch("productVariant");
  const product = getProduct(productConfig, getValues("productKey"));
  const hasLocations = product?.locations && product.locations.length > 1;
  // const hasMemberships = product?.memberships && product.memberships.length > 1;

  const handleContinue = async () => {
    const valid = await trigger(["productVariant"]);
    if (!valid) return;

    setValue("date", null);
    setValue("time", null);
    onNextStep();
  };

  const handleSelectProduct = async (
    location: ProductLocationOption | null,
    membership: ProductMembershipOption | null
  ) => {
    if (!product) return;

    const variant = getVariant(product, location, membership);
    setValue("productVariant", variant ?? null);
    setValue("date", null);
    setValue("time", null);

    await trigger(["productVariant"]);
  };

  if (product === null || product.variants.length === 0) {
    return (
      <p role="alert" className="text-center">
        Der er desværre ingen ledige ydelser.
      </p>
    );
  }

  return (
    <div role="main">
      <Stepper productConfig={productConfig} />

      {hasLocations && (
        <>
          <Indentation className="pb-8">
            <Title>Hvor ønsker du at konsultationen skal foregå?</Title>
            <p>Du er velkommen til at vælge en anden lokation, hvis det passer dig bedre.</p>
          </Indentation>

          <RadioButtonList>
            {product.locations?.map((location) => (
              <Controller
                name="productVariant.location"
                control={control}
                rules={{ required: true }}
                render={({ fieldState }) => {
                  // const variant = product.variants.find((p) => p.location.value === location.value);

                  return (
                    <RadioButton
                      groupName="location"
                      selected={location.value === productVariant?.location?.value}
                      error={!!fieldState.error}
                      onClick={() => handleSelectProduct(location, null)}
                      value={location.value}
                    >
                      <ProductContent
                        title={location.title}
                        duration={location.duration}
                        location={location.description}
                        // price={variant?.price}
                        // priceSuffix={variant?.priceSuffix}
                      />
                    </RadioButton>
                  );
                }}
                key={location.value}
              />
            ))}
          </RadioButtonList>
        </>
      )}

      {/*
      TODO: Remove once we are certain that we don't need memberships
      {hasMemberships && (
        <>
          <Title className="mt-10">
            Ønsker du et Hemi Medlemskab?
          </Title>

          <RadioButtonList>
            {product.memberships?.map((membership) => (
              <Controller
                name="productVariant.membership"
                control={control}
                rules={{ required: true }}
                render={({ fieldState }) => (
                  <RadioButton
                    groupName="membership"
                    selected={!!productVariant?.membership?.includes(membership.value)}
                    error={!!fieldState.error}
                    onClick={() => handleSelectProduct(null, membership)}
                    value={membership.value}
                  >
                    <h4 role="heading" className="font-serif font-medium">
                      {membership.title}
                    </h4>

                    {membership.description && (
                      <p className="text-sm whitespace-pre mb-2 sm:mb-1">
                        {membership.description}
                      </p>
                    )}
                  </RadioButton>
                )}
                key={membership.value}
              />
            ))}
          </RadioButtonList>
        </>
      )}
      */}

      <div className="flex gap-x-4 justify-end mt-10">
        <Button onClick={handleContinue} intent="primary" size="large" className="w-full sm:w-auto">
          Vis ledige tider
        </Button>
      </div>
    </div>
  );
};

const getVariant = (
  product: Product,
  location: ProductLocationOption | null,
  membership: ProductMembershipOption | null
) => {
  // Get variant by location and membership
  if (location && membership) {
    return product.variants.find(
      (p) => p.location.value === location.value && p.membership?.includes(membership.value)
    );
  }
  // Only by location
  else if (location) {
    return product.variants.find((p) => p.location.value === location.value);
  }
  // Only by membership
  else if (membership) {
    return product.variants.find((p) => p.membership?.includes(membership.value));
  }

  return undefined;
};
