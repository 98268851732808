import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { getProduct } from "../lib/utils";
import { StepContext } from "../step-context";
import { FormFields, ProductConfig } from "../types";
import { Indentation } from "./indentation";

type Props = {
  productConfig: ProductConfig;
};

export const Stepper = ({ productConfig }: Props) => {
  const { getValues } = useFormContext<FormFields>();
  const { stepNumber, totalStepNumbers } = useContext(StepContext);
  const product = getProduct(productConfig, getValues("productKey"));

  return (
    <Indentation className="flex justify-between pb-3">
      <p className="font-medium text-sm">{product?.shortName}</p>
      <p className="font-medium text-sm">
        {stepNumber} / {totalStepNumbers}
      </p>
    </Indentation>
  );
};
