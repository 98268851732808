import clsx from "clsx";

export const Indentation = ({
  className,
  breakpoint,
  children,
}: {
  className?: string;
  breakpoint?: "sm";
  children: React.ReactNode;
}) => <div className={clsx(breakpoint === "sm" ? "sm:px-6" : "px-6", className)}>{children}</div>;
