import { cva, type VariantProps } from "class-variance-authority";

const button = cva("disabled:opacity-30 disabled:pointer-events-none", {
  variants: {
    intent: {
      primary: ["bg-primary-default", "hover:bg-primary-hover", "text-base-10"],
      secondary: ["bg-secondary-default", "hover:bg-secondary-hover", "text-base-900"],
    },
    size: {
      small: ["text-sm", "w-full aspect-square ", "rounded-full"],
      large: [
        "text-large",
        "py-7",
        "leading-tight",
        "rounded-4xl",
        "min-w-[170px] sm:min-w-[180px]",
      ],
    },
    state: {
      error: ["ring-1", "ring-red-400"],
    },
  },
  compoundVariants: [{ intent: "primary", size: "large" }],
  defaultVariants: {
    intent: "primary",
    size: "large",
  },
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof button> {}

export const Button: React.FC<ButtonProps> = ({ className, intent, size, state, ...props }) => (
  <button type="button" className={button({ intent, size, className, state })} {...props} />
);
