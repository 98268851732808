import { format } from "date-fns";
import { InfoIcon, ClockIcon, MapIcon } from "./icons";
import { Title } from "./text";
import { currency } from "../lib/utils";

type Props = {
  title: string;
  duration?: number;
  startTime?: Date | null;
  endTime?: Date | null;
  location?: string;
  price?: number;
  priceSuffix?: string;
  showLabels?: boolean;
};

export const ProductContent = (props: Props) => {
  return (
    <>
      <Title size="sm">{props.title}</Title>

      <div className="flex flex-col gap-y-2.5">
        {props.duration && (
          <div className="flex items-center gap-x-1">
            <ClockIcon />
            <p className="text-sm">
              {props.showLabels && <Label>Tidspunkt</Label>}
              {props.duration} minutter
            </p>
          </div>
        )}

        {props.startTime && props.endTime && (
          <div className="flex items-center gap-x-1">
            <ClockIcon />
            <p className="text-sm">
              {props.showLabels && <Label>Tidspunkt</Label>}
              {format(props.startTime, "d MMM yyyy 'kl' HH:mm")} - {format(props.endTime, "HH:mm")}
            </p>
          </div>
        )}

        <div className="flex items-center gap-x-1">
          <MapIcon />
          <p className="text-sm">
            {props.showLabels && <Label>Sted</Label>}
            {props.location}
          </p>
        </div>

        {props.price !== undefined && (
          <div className="flex items-center gap-x-1">
            <InfoIcon />
            <p className="text-sm">
              {props.showLabels && <Label>Pris</Label>}
              <span className="whitespace-nowrap">
                {currency(props.price)}
                {props.priceSuffix}
              </span>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

const Label = ({ children }: { children: React.ReactNode }) => (
  <>
    <strong className="font-semibold">{children}</strong>:{" "}
  </>
);
